
import './App.css';

const Container = () => (
  <div style={{ height: '100%' }} >
    {/* <Topbar style={{position:'absolute'}}></Topbar>  */}
    <video autoplay="autoplay" loop="true" muted playsInline id="myVideo">
  <source src="video.webm" type="video/webm"/>
</video>
    
    <header class="viewport-header">
  
  <h1>
    
    <span>Northei</span>
  </h1>
  
</header>

<div  class="links">
<a  href='https://friliv.northei.no' rel="noopener noreferrer">
<h2 >Friliv
</h2>
</a>

</div>

    {/* <Parallax  blur={0} bgImage="/setre.png" bgImageAlt="image" strength={500}>
    
       <div style={{ height: 700 }}>
       
       <h1 style={insideStyles}>NORTHEI</h1>
      </div>
    </Parallax>
    <h2>| | |</h2>
    <Parallax  blur={0} bgImage="/setre.png" bgImageAlt="image" strength={0}>
    
    <div style={{ height: 500 }}>
    
    <div style={insideStyles}>HTML inside the parallax</div>
   </div>
 </Parallax> */}


    </div>
);
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

function App() {
  return (
 <div style={styles}>
 <Container></Container>
 </div>
  );
}

export default App;
